<template>
    <div class="shop-index">
        <div class="si-con" v-if="userInfo">
            <div class="si-con-left">
                <img class="avatar" v-if="userInfo.pic_url" :src="userInfo.pic_url" alt="">
                <img class="avatar" v-else src="@/assets/img/moren.png" alt="">
                <div class="si-con-right">
                    <div class="name">{{userInfo.name}}</div>
                    <div class="name">{{userInfo.mobile}} 
                        <router-link to="/shop/personal">
                            <span>设置</span>
                        </router-link> 
                    </div>
                </div>
            </div>
            <!-- 员工不能创建应用和添加员工了 -->
            <a-space v-if="userInfo.is_staff !=1">
                <router-link to="/shop/createShop">
                    <a-button type="primary"><i class="ri-add-line ri-btn"></i>创建农场</a-button>
                </router-link>
                <router-link to="/shop/employee">
                    <a-button type="primary">
                        <i class="ri-user-settings-line ri-btn"></i>员工管理
                    </a-button>
                </router-link>
            </a-space>
        </div>
    
        <div class="si-app">
            <div class="si-app-title mb12">农场列表</div>
            <div class="si-app-con">
                <a-tabs v-model:activeKey="app.server_id" size="small" @change="getMyProgram(1,app.limit)">
                    <a-tab-pane :key="0" tab="所有农场"></a-tab-pane>
                    <a-tab-pane v-for="item in server" :key="item.id" :tab="item.father_name"></a-tab-pane>
                    <template #tabBarExtraContent>
                            <a-input-search
                            v-model:value="app.key"
                            placeholder="农场名称"
                            enter-button
                            @search="getMyProgram(1,app.limit)"
                        />
                    </template>
                </a-tabs>
                <div class="none-con" v-if="app.list.length == 0">
                    <p>你还没有创建任何农场</p>
                    <router-link to="/shop/createShop">
                        <a-button type="primary">免费创建</a-button>
                    </router-link>
                </div>
                <div v-else>
                    <ul class="si-shop">
                        <li v-for="(item,ind) in app.list" :key="ind" @click="toProgram(item.program_id)">
                            <div class="status" v-if="item.is_use == 1">使用中</div>
                            <div class="status" style="background:#FF0066" v-else>已过期</div>

                            <div class="status version">
                                {{ item.server.server_name}}
                                <span v-if="item.is_trail == 1">(试用版)</span>
                            </div>
                            <div>
                                <img class="logo" :src="item.logo" alt="">
                                <div class="sis-right">
                                    <div class="name">{{item.program_name}}</div>
                                    <div class="desc">APPID:{{item.program_id}}</div>
                                    <div class="desc">
                                        剩余天数:{{item.end_days}}天
                                        <router-link :to="{path:'/shop/shopRenew',query:{id:item.program_id}}">
                                            <span @click.stop="()=>{return false}" class="renew">续费</span>
                                        </router-link>
                                    </div>
                                    <div class="edit">
                                        <span @click.stop="editProgram(item)">编辑</span>
                                        <span @click.stop="deleteProgram(item.program_id)" v-if="item.end_days<=0">删除</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="pager">
                        <a-pagination 
                            hideOnSinglePage
                            show-size-changer 
                            v-model:current="app.page" 
                            v-model:pageSize="app.limit" 
                            :total="app.count" 
                            @showSizeChange="(p,e)=>{getMyProgram(app.page,e)}"
                            @change="(e)=>getMyProgram(e,app.limit)"
                        />
                    </div>
                </div>
            </div>
        </div>
        
        <a-modal v-model:visible="showForm" title="编辑店铺信息" @ok="saveShop" @cancel="showForm = false">
            <a-form :label-col="{span:6}" :wrapper-col="{span:14}">
                <a-form-item label="农场名称">
                    <a-input v-model:value="form.program_name"></a-input>
                </a-form-item>
                <a-form-item label="农场logo">
                    <kd-img-select :src="form.logo" @change="e=>form.logo = e"></kd-img-select>
                </a-form-item>
            </a-form>
        </a-modal>

    </div>
</template>
<script>
import appModel from '@/api/saas/app.js'
import userModel from '@/api/user.js'
import { reactive, toRefs } from 'vue'
import appList from '@/components/admin/appList.vue';
import serviceModel from '@/api/saas/service.js'
export default {
    components:{
        appList
    },
    setup() {
        const _d = reactive({
            app:{
                list:[],
                page:1,
                limit:10,
                count:0,
                key:"",
                server_id:0,
            },
            server:[],
            form:{
                logo:"",
                program_id:0,
                program_name:"",
            },
            
            showForm:false,
            userInfo:null
        })
        getMyProgram(1,10)

        serviceModel.getServerScheme(1,999,res=>_d.server = res.list)
        
        userModel.getUserDetail().then(res=>{
            _d.userInfo = res
        })  
        function getMyProgram(page,limit){
            appModel.getProgramList(1,page,limit,{key:_d.app.key,server_id:_d.app.server_id},(res)=>{
                _d.app.list = res.list
                _d.app.count = res.count
            })
        }
        function editProgram(data){
            console.log('data',data);
            _d.form = {
                logo:data.logo,
                is_trail:data.is_trail,
                program_id:data.program_id,
                program_name:data.program_name,
                config:{
                    wx_use_open: '0',
                    wx_mini_use_open: '0'
                }
            }
            _d.showForm = true
            return
        }
        function saveShop(){
            appModel.addOrEditProgram('edit',_d.form,()=>{
               _d.showForm = false 
               getMyProgram(_d.app.page,_d.app.limit)
            })
        }

        function deleteProgram(id){
            appModel.changeProgramStatus(id,1,()=>{
                getMyProgram(_d.app.page,_d.app.limit)
            })
        }

        function toProgram(id){
            appModel.intoProgram(id)
        }

        return {
            ...toRefs(_d),
            saveShop,
            editProgram,
            getMyProgram,
            deleteProgram,
            toProgram
        }
    },
}
</script>
<style lang="scss" scoped>
body{
    padding: 0;
    margin: 0;
}
.shop-index{
    background: #f4f4f4;

    .bg{
        background: #f0f2f5;
    }
}
.si-con{
    background: #fff;
    min-height: 72px;
    display: flex;
    align-items: center;
    padding: 0 24px; 
    justify-content: space-between;

    &-left{
        display: flex;
        align-items: center;

        .avatar{
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 12px;
        }
    }
    .name{
        span{
            color: #3399FF;
            cursor: pointer;
        }
    }
}

.si-app{
    background: #fff;
    min-height: 400px;
    margin-top: 24px;
    padding: 0 24px;

    &-title{
        width: 100%;
        height: 60px;
        font-size: 20px;
        font-weight: bold;
        line-height: 60px;
        border-bottom: 1px solid #f4f4f4;
    }

    .none-con{
        width: 100%;
        text-align: center;
        padding-top: 96px;
    }
}

.si-shop{
    list-style: none;
    padding: 0;

    >li{
        display: inline-block;
        width: 270px;
        height: 144px;
        border: 1px solid #f0f0f0;
        margin: 0 12px 12px 0;
        padding: 30px 16px;
        cursor: pointer;
        position: relative;

        >div{
            display: flex;
        }
    }
    .renew{
        display: none;
        color: #3399FF;
        font-size: 13px;
    }
    >li:hover .edit,>li:hover .renew{
        display: inline-block;
    }
    .edit{
        display: none;
        color: #3399FF;
        font-size: 13px;
        span{
            margin-right: 12px;
        }
    }
    .logo{
        width: 80px;
        height: 80px;
        margin-right: 12px;
        border-radius: 6px;
    }
    .name{
        font-weight: bold;
        font-size: 16px;
    }
    .desc{
        color: #999;
        font-size: 12px;
    }
    .status{
        position: absolute;
        height: 18px;
        font-size: 12px;
        background: #00CC66;
        color: #fff;
        left: 0;
        top: 0;
        padding: 0 12px;
        border-radius: 0 0 18px 0;
        line-height: 18px;
        z-index: 99;
    }

    .version{
        background: #9966FF;
        padding: 0 16px 0 64px;
        z-index:9;
    }
}
</style>